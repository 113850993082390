<template>
  <main>
    <List class="wa__large wa__large__page mb-10">
      <section-title title="BASIC INFO"></section-title>
      <div class="d-flex">
        <div class="mr-4">
          <div class="wa__basic__info__image mt-2" :style="isChanged('model.thumbnail') ? 'border:2px dashed red' : ''">
            <div
              class="wa__basic__info__image--image"
              :style="{
                backgroundImage: `url(${modelAbout.model.thumbnail})`,
              }"
            ></div>
            <div v-if="isChanged('model.thumbnail')">
              <v-checkbox v-model="acceptedField" value="model.thumbnail"></v-checkbox>
            </div>
          </div>
        </div>
        <div>
          <div class="wa__basic__info__information">
            <circle-button
                v-show="compareDataReady"
                icon="WMi-ok"
                color="white"
                bg-color="green"
                style="position: fixed;top: 5rem;right: 4rem;"
                @click.native="acceptUpdatedData"
            />
            <circle-button
                v-show="showUpdatedDataButton"
                icon="WMi-eye"
                color="white"
                bg-color="blue"
                style="position: fixed;top: 5rem;right: 7rem;"
                @click.native="checkUpdatedData"
            />
            <circle-button
                v-show="compareDataReady"
                icon="WMi-cancel"
                color="white"
                bg-color="red"
                style="position: fixed;top: 5rem;right: 1rem;"
                @click.native="rejectUpdatedData"
            />
            <ul
              class="wa__basic__info__information--information list-unstyled pl-0 mt-1"
            >
              <request-details-information
                title="id"
                :sub-title="modelAbout.unique_id"
              ></request-details-information>
              <request-details-information
                title="first name"
                :isChanged="isChanged('first_name')"
                :sub-title="modelAbout.first_name"
              >
                <v-checkbox v-model="acceptedField" value="first_name"></v-checkbox>
              </request-details-information>

              <request-details-information
                title="last name"
                :isChanged="isChanged('last_name')"
                :sub-title="modelAbout.last_name"
              >
                <v-checkbox v-model="acceptedField" value="last_name"></v-checkbox>
              </request-details-information>
              <request-details-information
                title="job experience"
                :isChanged="isChanged('model.job_experience')"
                :sub-title="modelAbout.model.job_experience"
              >
                <v-checkbox v-model="acceptedField" value="model.job_experience"></v-checkbox>
              </request-details-information>
              <request-details-information
                title="bio"
                :isChanged="isChanged('bio')"
                :sub-title="modelAbout.bio"
              >
                <v-checkbox v-model="acceptedField" value="bio"></v-checkbox>
              </request-details-information>
              <request-details-information
                title="skills"
                :isChanged="isChanged('model.skills')"
                :sub-title="modelAbout.model.skills"
              >
                <v-checkbox v-model="acceptedField" value="model.skills"></v-checkbox>
              </request-details-information>
              <request-details-information
                title="tattoo"
                :isChanged="isChanged('model.tattoo')"
                :sub-title="modelAbout.model.tattoo"
              >
                <v-checkbox v-model="acceptedField" value="model.tattoo"></v-checkbox>
              </request-details-information>
            </ul>
          </div>
        </div>
      </div>
      <div class="wa__photo__gallery">
        <section-title title="photo gallery"></section-title>
        <div class="d-flex flex-wrap mt-2">
          <div
            v-for="(photo, key) in modelAbout.model.galleries"
            :key="photo.id"
            class="ma-1"
          >
            <div class="wa__basic__info__image">
              <div
                class="wa__basic__info__image--image"
                :style="{ backgroundImage: `url(${photo.thumbnail_w300})` }"
                @click="showImage(key)"
              ></div>
            </div>
            <light-box
              :media="galleries"
              :showLightBox="false"
              ref="lightbox"
            ></light-box>
          </div>
        </div>
      </div>
      <div class="wa__japanese__info">
        <section-title title="japanese info"></section-title>
        <ul class="wa__japanese__info--info list-unstyled pl-0 mt-3">
          <request-details-information
            title="last name"
            :isChanged="isChanged('last_name_jpn')"
            :subTitle="modelAbout.last_name_jpn"
          >
            <v-checkbox v-model="acceptedField" value="last_name_jpn"></v-checkbox>
          </request-details-information>
          <request-details-information
            title="first name"
            :isChanged="isChanged('first_name_jpn')"
            :subTitle="modelAbout.first_name_jpn"
          >
            <v-checkbox v-model="acceptedField" value="first_name_jpn"></v-checkbox>
          </request-details-information>
          <request-details-information
            title="job experience"
            :isChanged="isChanged('model.job_experience_jpn')"
            :subTitle="modelAbout.model.job_experience_jpn"
          >
            <v-checkbox v-model="acceptedField" value="model.job_experience_jpn"></v-checkbox>
          </request-details-information>
          <request-details-information
            title="bio"
            :isChanged="isChanged('bio_jpn')"
            :subTitle="modelAbout.bio_jpn"
          >
            <v-checkbox v-model="acceptedField" value="model.bio_jpn"></v-checkbox>
          </request-details-information>
          <request-details-information
            title="slills"
            :isChanged="isChanged('model.skills_jpn')"
            :subTitle="modelAbout.model.skills_jpn"
          >
            <v-checkbox v-model="acceptedField" value="model.skills_jpn"></v-checkbox>
          </request-details-information>
          <request-details-information
            title="tattoo"
            :isChanged="isChanged('model.tattoo_jpn')"
            :subTitle="modelAbout.model.tattoo_jpn"
          >
            <v-checkbox v-model="acceptedField" value="model.tattoo_jpn"></v-checkbox>
          </request-details-information>
        </ul>
      </div>
      <div class="wa__general__info">
        <section-title title="general info"></section-title>
        <ul class="wa__general__info--info list-unstyled pl-0 mt-3">
          <ul class="pl-0 d-flex justify-content-between">
            <request-details-information
              title="birthday"
              :isChanged="isChanged('birthday')"
              :sub-title="modelAbout.birthday"
            >
              <v-checkbox v-model="acceptedField" value="birthday"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="email"
              :isChanged="isChanged('email')"
              :sub-title="modelAbout.email"
            >
              <v-checkbox v-model="acceptedField" value="email"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="cell phone"
              :isChanged="isChanged('cell_phone')"
              :sub-title="modelAbout.cell_phone"
            >
              <v-checkbox v-model="acceptedField" value="model.cell_phone"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="instagram id"
              :isChanged="isChanged('model.instagram_id')"
              :sub-title="modelAbout.model.instagram_id"
            >
              <v-checkbox v-model="acceptedField" value="model.instagram_id"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="show instagram followers count"
              :isChanged="isChanged('model.is_show_follower_count')"
              :sub-title="
                modelAbout.model.is_show_follower_count ? 'yes' : 'no'
              "
              class="mr-2"
            >
              <v-checkbox v-model="acceptedField" value="model.is_show_follower_count"></v-checkbox>
            </request-details-information>
          </ul>
          <ul class="pl-0 d-flex justify-space-between">
            <request-details-information
              title="youtube id"
              :isChanged="isChanged('model.youtube')"
              :sub-title="modelAbout.model.youtube"
            >
              <v-checkbox v-model="acceptedField" value="model.youtube"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="show youtube followers count"
              :isChanged="isChanged('model.is_show_subscribe_count')"
              :sub-title="
                modelAbout.model.is_show_subscribe_count ? 'yes' : 'no'
              "
            >
              <v-checkbox v-model="acceptedField" value="model.is_show_subscribe_count"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="line id"
              :isChanged="isChanged('model.line_id')"
              :sub-title="modelAbout.model.line_id"
            >
              <v-checkbox v-model="acceptedField" value="model.line_id"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="country"
              :isChanged="isChanged('country.id')"
              :sub-title="modelAbout.country ? modelAbout.country.name : ''"
            >
              <v-checkbox v-model="acceptedField" value="country"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="city"
              :isChanged="isChanged('city')"
              :sub-title="modelAbout.city"
              class="mr-0"
            >
              <v-checkbox v-model="acceptedField" value="city"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="nearest station"
              :isChanged="isChanged('model.nearest_station')"
              :sub-title="modelAbout.model.nearest_station"
            >
              <v-checkbox v-model="acceptedField" value="model.nearest_station"></v-checkbox>
            </request-details-information>
          </ul>
          <ul class="pl-0 mt-5 w-100 d-flex justify-space-between">
            <request-details-information
                title="address"
                :sub-title="modelAbout.model.address"
                :isChanged="isChanged('model.address')"
            >
              <v-checkbox v-model="acceptedField" value="model.address"></v-checkbox>
            </request-details-information>
            <li class="request__details__information mr-0">
              <div class="d-block ml-1">
                <div :style="isChanged('model_types') ? 'color:red' : ''" class="request__details__information--title">
                  model types
                </div>
                <div class="request__details__information--sub-title d-flex">
                  <v-chip
                    class="mr-1 request__details__information--sub-title--block"
                    color="#e6e6e68f"
                    :text-color="isChanged('model_types') ? 'red' : 'black'"
                    label
                    x-small
                    v-for="modelType in modelAbout.model_types"
                    :key="modelType.id"
                  >
                    {{ modelType.name }}
                  </v-chip>
                  <v-checkbox v-if="isChanged('model_types')" v-model="acceptedField" value="model_types"></v-checkbox>
                </div>
              </div>
            </li>
            <request-details-information
              title="Nationality"
              :isChanged="isChanged('model.ethnic')"
              :sub-title="modelAbout.ethnic ? modelAbout.ethnic.name : ''"
              class="ethnic-list"
            >
              <v-checkbox v-model="acceptedField" value="model.ethnic"></v-checkbox>
            </request-details-information>
            <request-details-information
                title="Apartment"
                :isChanged="isChanged('model.apartment')"
                :sub-title="modelAbout.model.apartment">
              <v-checkbox v-model="acceptedField" value="model.apartment"></v-checkbox>
            </request-details-information>
            <li class="request__details__information">
              <div class="d-block ml-1">
                <div :style="isChanged('languagesWithLevel') ? 'color:red' : ''" class="request__details__information--title">
                  languages
                </div>
                <div class="request__details__information--sub-title d-flex">
                  <v-chip
                    class="mr-1 request__details__information--sub-title--block"
                    color="#e6e6e68f"
                    :text-color="isChanged('languagesWithLevel') ? 'red' : 'black'"
                    label
                    x-small
                    v-for="language in modelAbout.languagesWithLevel"
                    :key="language.id"
                  >
                    {{ language.name }} - {{ language.level_name }}
                  </v-chip>
                  <v-checkbox v-if="isChanged('languagesWithLevel')" v-model="acceptedField" value="languagesWithLevel"></v-checkbox>
                </div>
              </div>
            </li>
            <request-details-information
              title="BI-NATIONALITY"
              :isChanged="isChanged('model.is_hybrid')"
              :sub-title="modelAbout.model.is_hybrid ? 'yes' : 'no'"
            >
              <v-checkbox v-model="acceptedField" value="model.is_hybrid"></v-checkbox>
            </request-details-information>
          </ul>
        </ul>
      </div>
      <div class="wa__physical__info">
        <section-title title="physical info"></section-title>
        <div class="wa__physical__info--list">
          <ul class="wa__physical__info--info list-unstyled pl-0 mt-3">
            <request-details-information
              title="Weight - KG"
              :isChanged="isChanged('model.weight')"
              :sub-title="modelAbout.model.weight"
            >
              <v-checkbox v-model="acceptedField" value="model.weight"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="height - CM"
              :isChanged="isChanged('model.height')"
              :sub-title="modelAbout.model.height"
            >
              <v-checkbox v-model="acceptedField" value="model.height"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="bust - CM"
              :isChanged="isChanged('model.bust')"
              :sub-title="modelAbout.model.bust"
            >
              <v-checkbox v-model="acceptedField" value="model.bust"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="waist - CM"
              :isChanged="isChanged('model.waist')"
              :sub-title="modelAbout.model.waist"
            >
              <v-checkbox v-model="acceptedField" value="model.waist"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="hips - CM"
              :isChanged="isChanged('model.hips')"
              :sub-title="modelAbout.model.hips"
            >
              <v-checkbox v-model="acceptedField" value="model.hips"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="shoe"
              :isChanged="isChanged('model.shoes')"
              :sub-title="modelAbout.model.shoes"
            >
              <v-checkbox v-model="acceptedField" value="model.shoes"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="hair"
              :isChanged="isChanged('model.hair_color.name')"
              :sub-title="modelAbout.model.hair_color.name"
            >
              <v-checkbox v-model="acceptedField" value="model.hair_color"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="eye"
              :isChanged="isChanged('model.eye_color.name')"
              :sub-title="modelAbout.model.eye_color.name"
            >
              <v-checkbox v-model="acceptedField" value="model.eye_color"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="gender"
              :isChanged="isChanged('model.gender')"
              v-if="modelAbout.gender"
              :sub-title="modelAbout.gender.name"
            >
              <v-checkbox v-model="acceptedField" value="gender"></v-checkbox>
            </request-details-information>
          </ul>
        </div>
      </div>
      <div class="wa__bank__info">
        <section-title title="bank info"></section-title>
        <div class="wa__bank__info--list">
          <ul class="wa__bank__info--info list-unstyled pl-0">
            <request-details-information
              title="bank name"
              :isChanged="isChanged('model.bank_name')"
              :sub-title="modelAbout.model.bank_name"
            >
              <v-checkbox v-model="acceptedField" value="model.bank_name"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="branch name"
              :isChanged="isChanged('model.branch_name')"
              :sub-title="modelAbout.model.branch_name"
            >
              <v-checkbox v-model="acceptedField" value="model.branch_name"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="account no"
              :isChanged="isChanged('model.account_no')"
              :sub-title="modelAbout.model.account_no"
            >
              <v-checkbox v-model="acceptedField" value="model.account_no"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="account name"
              :isChanged="isChanged('model.account_name')"
              :sub-title="modelAbout.model.account_name"
            >
              <v-checkbox v-model="acceptedField" value="model.account_name"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="account classification"
              :isChanged="isChanged('model.account_classification.name')"
              :sub-title="modelAbout.model.account_classification.name"
            >
              <v-checkbox v-model="acceptedField" value="model.account_classification"></v-checkbox>
            </request-details-information>
            <request-details-information
              v-if="!isCountryJapan"
              title="type of visa"
              :isChanged="isChanged('model.type_of_visa')"
              :sub-title="modelAbout.model.type_of_visa"
            >
              <v-checkbox v-model="acceptedField" value="model.type_of_visa"></v-checkbox>
            </request-details-information>
            <request-details-information
              v-if="!isCountryJapan"
              title="valid until"
              :isChanged="isChanged('model.valid_until')"
              :sub-title="modelAbout.model.valid_until"
            >
              <v-checkbox v-model="acceptedField" value="model.valid_until"></v-checkbox>
            </request-details-information>
            <request-details-information
              title="other"
              :isChanged="isChanged('model.other')"
              :sub-title="modelAbout.model.other"
            >
              <v-checkbox v-model="acceptedField" value="model.other"></v-checkbox>
            </request-details-information>
          </ul>
        </div>
      </div>
    </List>
  </main>
</template>
<script>
import List from "@/components/Global/Section/List.vue";
import SectionTitle from "@/components/Global/Section/SectionTitle.vue";
import RequestDetailsInformation from "@/components/Global/Section/RequestDetailsInformation.vue";
import LightBox from "vue-image-lightbox";
export default {
  props: {
    fieldsAccepted: {
      default: () => [],
    },
    compareData: {
      default: () => [],
    },
    compareDataReady: {
      default: () => [],
    },
    isUpdated: {
      default: () => [],
    },
    modelAbout: {
      default: () => ({
        gender: {},
        model: {
          eye_color: {},
          hair_color: {},
          account_classification: {},
        },
        model_types: [],
        languagesWithLevel: [],
      }),
    },
    modelAboutObject: {
      default: () => ({
        gender: {},
        model: {
          eye_color: {},
          hair_color: {},
          account_classification: {},
        },
        model_types: [],
        languagesWithLevel: [],
      }),
    },
    galleries: {
      default: () => [],
    },
  },
  components: {
    List,
    SectionTitle,
    RequestDetailsInformation,
    LightBox,
  },
  data() {
    return {
      title: "",
      subTitle: "",
      // BACK TO HOME
      backRouteName: "models",
      backText: "all models",
    };
  },
  computed: {
    showUpdatedDataButton(){
      if(this.compareDataReady){
        if(this.modelAboutObject.model_updated_not_appended!=null){
          return true;
        }
      } else {
        if(this.modelAbout.model_updated_not_appended==null){
          return false;
        } else if(this.modelAbout.model_updated_not_appended.items_updated===false){
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
    isCountryJapan() {
      return this.modelAbout.ethnic_id === 1;
    },
    acceptedField:{
      set(field){
        this.$emit('accept-field',field)
      },
      get(){
        return this.fieldsAccepted
      }
    }
  },
  methods: {
    checkUpdatedData(){
      this.$emit('append-data')
    },
    acceptUpdatedData(){
      this.$emit('accept-update')
    },
    rejectUpdatedData(){
      this.$emit('reject-update')
    },
    isChanged(field){
      // console.log(this.compareData)
      if(this.compareDataReady) {
        if (this.compareData.length > 0)
          return this.compareData.includes(field)
      }
      return false;
    },
    showImage(index) {
      this.$refs.lightbox[0].showImage(index);
    },
  },
};
</script>
<style scoped>
/* basic info image */
.wa__basic__info__image {
  width: 163px;
  height: 150px;
  padding: 5px;
  border: 1px solid var(--color-light-gray);
  border-radius: 6px;
}
.wa__basic__info__image--image {
  height: 100%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

/* basic info*/
.wa__basic__info__information {
  padding-right: 155px;
}
.wa__basic__info__information--information li {
  display: inline-flex;
}
.wa__basic__info__information--information li::before {
  height: 27px;
}
.wa__basic__info__information--information li:nth-child(6) {
  margin-right: 250px;
}
/* photo gallery*/
.wa__photo__gallery {
  margin-top: 70px;
}
/* japanese info*/
.wa__japanese__info {
  margin-top: 100px;
}
.wa__japanese__info--info li {
  display: inline-flex;
}
.wa__japanese__info--info li:nth-child(2) {
  margin-right: 235px;
}
.wa__japanese__info--info li:nth-child(5) {
  margin-right: 535px;
}
/* general info*/
.wa__general__info {
  margin-top: 100px;
}
.wa__general__info--info {
  padding-right: 150px;
}
.wa__general__info--info li {
  display: inline-flex;
}
.wa__general__info--info li:nth-child(5) {
  margin-right: 335px;
}
.ethnic-list {
  margin-right: 120px !important;
}
.request__details__information {
  display: flex;
  margin: 0 50px 0 0;
}
.request__details__information::before {
  height: 20px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 0;
}
.request__details__information--title {
  color: var(--color-smoky);
  font-size: 12px;
  font-family: "Montserrat-ExtraLight";
  text-transform: uppercase;
}
.request__details__information--sub-title--block {
  font-family: "Montserrat-regular";
}
/* physical info*/
.wa__physical__info {
  margin-top: 100px;
}
.wa__physical__info--list {
  width: 520px;
}
.wa__physical__info--info li {
  display: inline-flex;
  margin-bottom: 10px;
}
/* bank info*/
.wa__bank__info {
  margin-top: 100px;
}
.wa__bank__info--info li {
  display: inline-flex;
  margin: 10px 129px 0px 0;
}
.wa__bank__info--info li:nth-child(7) {
  display: inline-flex;
  margin-right: 120px;
}
</style>
